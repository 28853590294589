@font-face {
  font-family: "Jetbrains Mono";
  src: url("../assets/fonts/JetBrainsMonoNL-Regular.ttf") format("truetype");
}

*:not(span) {
  transition-duration: 250ms;
}

span {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.font-jetbrains-mono {
  font-family: "Jetbrains Mono", monospace;
}

::-webkit-scrollbar {
  width: 0;
}

::-webkit-scrollbar-track {
  background: #00000000;
}

::-webkit-scrollbar-thumb {
  border-radius: 2008px;
  background: #10b981;
}

::selection {
  background-color: #ffffff20;
}

#test-text span {
  position: relative;
  z-index: 100;
}

.animate-up-bouncy {
  animation: animateUpBouncy 600ms ease-in-out;
}

@keyframes animateUpBouncy {
  0% {
    transform: translateY(20px);
    opacity: 0;
    filter: blur(4px);
  }
  40% {
    transform: translateY(-10px);
    opacity: 0.7;
    filter: blur(2px);
  }
  70% {
    transform: translateY(5px);
    opacity: 1;
    filter: blur(0);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
    filter: blur(0);
  }
}
