@font-face {
  font-family: Jetbrains Mono;
  src: url("JetBrainsMonoNL-Regular.7969212e.ttf") format("truetype");
}

:not(span) {
  transition-duration: .25s;
}

span {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.font-jetbrains-mono {
  font-family: Jetbrains Mono, monospace;
}

::-webkit-scrollbar {
  width: 0;
}

::-webkit-scrollbar-track {
  background: none;
}

::-webkit-scrollbar-thumb {
  background: #10b981;
  border-radius: 2008px;
}

::selection {
  background-color: #ffffff20;
}

#test-text span {
  z-index: 100;
  position: relative;
}

.animate-up-bouncy {
  animation: .6s ease-in-out animateUpBouncy;
}

@keyframes animateUpBouncy {
  0% {
    opacity: 0;
    filter: blur(4px);
    transform: translateY(20px);
  }

  40% {
    opacity: .7;
    filter: blur(2px);
    transform: translateY(-10px);
  }

  70% {
    opacity: 1;
    filter: blur();
    transform: translateY(5px);
  }

  100% {
    opacity: 1;
    filter: blur();
    transform: translateY(0);
  }
}
/*# sourceMappingURL=index.c2db8182.css.map */
